<template>
    <a class="item-header">
        <div class="left" @click="$emit( 'toggleItem', item)">
            <i v-if="optionsViewId && ! isPurchaseItem" class="fal fa-lg fa-box-usd" />
            <i v-else-if="optionsViewId && isPurchaseItem" class="fal fa-lg fa-industry-alt" />
            <h4 v-html="orderId" />
            <i
                v-if="action === 'load' && packingSlip || order"
                class="icon fal fa-forklift"
                :style="`color: ${pickingColor}; margin-left: 5px;`"
            />
        </div>
        <div class="middle" @click="$emit('toggleItem', item)">
            <template v-if="fullAddress">
                <div v-if="customer && (settings.always_display_customer_at_options_view || (customer.phone && customer.phone !== address.phone))" class="address customer">
                    <div class="delivery-description">
                        {{ $t('customer') }}:
                    </div>
                    <h4 class="attention-of">{{ customer.fullCompanyName }}</h4>
                    {{ customer.phone }}
                </div>
                <div class="address delivery">
                    <div class="delivery-description">
                        <template v-if="isPurchaseItem">
                            {{ $t('pick_up_address') }}:
                        </template>
                        <template v-else>
                            {{ $t('delivery_address') }}:
                        </template>
                    </div>
                    <h4 class="attention-of">{{ address.attentionOf }}</h4>
                    <div v-html="addressString" />
                </div>
            </template>
            <template v-else>
                <h4 class="uk-text-truncate">{{ loadAddressString }}</h4>
                <div>
                    <span v-if="isPurchaseItem">
                        {{ supplierName }}
                    </span>
                    <span v-else-if="action === 'load' && pickingLocation">
                        {{ pickingLocation }}
                        <template v-if="customer && customer.fullCompanyName"> | </template>
                    </span>
                    <span v-else-if="customer && customer.fullCompanyName">
                        {{ customer.fullCompanyName }}
                    </span>
                </div>
            </template>
        </div>
        <div
            class="weight"
            :class="toRight ? 'uk-text-right' : ''"
            :style="[ ! fullAddress ? 'padding: 7px 0 0 0' : '' ]"
            @click="$emit('toggleItem', item)"
        >
            <div class="pallet">
                <span :style="optionsViewId ? 'margin-right: 5px' : ''">{{ item.palletCount }}</span>
                <i v-if="item.calculatedPalletCount" class="fal fa-calculator" />
                <i v-else class="fal fa-pallet-alt" />
            </div>
            <span>
                {{ weight }} {{ $t('ton_short') }}
            </span>

        </div>
        <div class="right">
            <button
                v-if="action === 'view' || (action === 'load' && allLoaded)"
                class="play"
                :class="loadedButtonColor"
                :key="'item-play-'+item.id"
                :disabled="disableOptionsView"
                @click="optionsView"
            >
                <i class="fal fa-play" />
            </button>
            <input
                v-else-if="showLoadAllCheckbox"
                type="checkbox"
                class="uk-checkbox play"
                @click="$emit('loadAll')"
            />
            <input
                v-else-if="action === 'load' && purchaseReceipt"
                :checked="allLoaded"
                type="checkbox"
                class="uk-checkbox play"
                :disabled="true"
            />
            <input
                v-else-if="action === 'unload'"
                :checked="allUnloaded"
                type="checkbox"
                class="uk-checkbox play"
                :disabled="allUnloaded"
                @change="$emit('unloadAll')"
            />
        </div>
    </a>
</template>

<style src="./LoadUnload.scss" lang="scss">

</style>

<script>
import { executeFromVersion } from "@/support/codeVersion";
import { getSettings }        from "@/support/settings";
import roundTo                from '@/support/roundTo';
import {
    COMPLETELY_PICKED,
    ITEM_STATUS,
    ITEM_TYPE,
    NOT_PICKED,
    PACKING_SLIP_STATUS_FINALIZED,
    PARTIALLY_PICKED,
    PURCHASE_RECEIPT_LINE_LOAD_STATUS,
    RIDE_STATUS
} from '@/constants';

export default {
    name: "ItemHeading",

    props: {
        action:        { type: String,  default:  '' },
        fullAddress:   { type: Boolean, default:  false },
        item:          { type: Object,  required: true },
        optionsViewId: { type: Boolean, default:  false },
        ride:          { type: Object,  required: true },
        toRight:       { type: Boolean, default:  false },
    },
    data() {
        return {
            settings: getSettings(),
        }
    },
    computed: {
        address() {
            if      (this.packingSlip) return this.packingSlip.deliveryAddress;
            else if (this.order)       return this.order.deliveryAddress;
            else if (this.item.type)   return this.item.pickUpAddress;
            else                       throw new Error('Unknown item type');
        },
        allLoaded() {
            if (this.purchaseReceipt) {
                for (const line of this.purchaseReceipt.lines) {
                    if (line.loadStatus < PURCHASE_RECEIPT_LINE_LOAD_STATUS.LOADED) return false;
                }
                return true;
            } else if (this.packingSlip) {
                for (const line of this.packingSlip.lines) {
                    if (! line.loaded) return false;
                }
                return true;
            }
            return false;
        },
        allUnloaded() {
            if (! this.packingSlip) return false;

            for (const line of this.packingSlip.lines) {
                if (Number(line.quantityUndelivered) !== 0) return false;
            }
            return true;
        },
        addressString() {
            let string = '';
            if (this.address.phone) string += this.address.phone.trim();

            if (this.address.address) {
                if (string) string += '<br>';
                string += this.address.address.trim();
            }
            if (this.postcodeCityString) {
                if (string) string += '<br>';
                string += this.postcodeCityString;
            }
            if (this.districtCountryString) {
                if (string) string += '<br>';
                string += this.districtCountryString;
            }
            return string;
        },
        customer() {
            return this.packingSlip
                ? this.packingSlip.customer
                : null
            ;
        },
        disableOptionsView() {
            if (this.ride.status === RIDE_STATUS.CONCEPT) {
                return true;

            } else if (this.item.type && this.item.type === ITEM_TYPE.PURCHASE_RECEIPT) {
                return (this.ride.status === RIDE_STATUS.COMPLETED && ! this.settings.less_restrictions_for_starting_delivery);

            } else if (this.packingSlip) {
                return ! this.allLoaded;
            }
            return true;
        },
        districtCountryString() {
            const country = this.address.country
                ? this.address.country.trim()
                : ''
            ;
            const string = this.address.district.trim() + ' ' + country;
            return string.trim();
        },
        isPurchaseItem() {
            return (this.item.type && (this.item.type === ITEM_TYPE.PURCHASE_ORDER || this.item.type === ITEM_TYPE.PURCHASE_RECEIPT));
        },
        loadAddressString() {
            let string = '';

            if (this.address.city.trim() !== '') string = this.address.city.trim();

            if (this.address.address.trim() !== '') {
                if (string !== '') string += ' ';

                string += this.address.address.trim();
            }
            if (this.address.postcode.trim !== '') {
                if (string !== '') string += ' ';

                string += this.address.postcode.trim();
            }
            if (this.address.country && this.address.country.trim() !== '') {
                if (string !== '') string += ' ';

                string += this.address.country.trim();
            }
            return string;
        },
        loadedButtonColor() {
            if (this.item.type && this.item.type === ITEM_TYPE.PURCHASE_RECEIPT) {
                if (this.item.itemStatus === ITEM_STATUS.COMPLETED) return 'play-green';
                else                                                return 'play-blue';
            }
            if (! this.packingSlip) return 'play-yellow';

            if (this.packingSlip.status === PACKING_SLIP_STATUS_FINALIZED) return 'play-green';

            return this.allLoaded
                ? 'play-blue'
                : 'play-yellow'
            ;
        },
        order() {
            return this.item.order;
        },
        orderId() {
            if      (this.packingSlip)     return this.packingSlip.order;
            else if (this.order)           return this.order.id;
            else if (this.purchaseOrder)   return this.item.purchaseOrder;
            else if (this.purchaseReceipt) return this.item.purchaseOrder.join(',<br>');
            else                           throw new Error('Unknown item type');
        },
        packingSlip() {
            return this.item.packingSlip;
        },
        purchaseOrder() {
            return (this.item.type && this.item.type === ITEM_TYPE.PURCHASE_ORDER)
                ? this.item.purchaseOrder
                : null
            ;
        },
        purchaseReceipt() {
            return (this.item.type && this.item.type === ITEM_TYPE.PURCHASE_RECEIPT)
                ? this.item.purchaseReceipt
                : null
            ;
        },
        pickingColor() {
            if (! this.packingSlip && ! this.order) {
                return null;
            }
            const picked = this.packingSlip
                ? this.packingSlip.picked
                : this.order.picked
            ;
            switch (picked) {
                case PARTIALLY_PICKED:
                    return '#ea9610';
                case COMPLETELY_PICKED:
                    return '#298337';
                case NOT_PICKED:
                default:
                    return '#b61919';
            }
        },
        pickingLocation() {
            if (this.packingSlip) return this.packingSlip.pickingLocation;
            else if (this.order)  return this.order.pickingLocation;
            else                  return '';
        },
        postcodeCityString() {
            const string = this.address.postcode + ' ' + this.address.city;
            return string.trim();
        },
        rideContainsPurchaseReceipt() {
            this.ride.items.forEach((item) => {
                if (item.type && item.type === ITEM_TYPE.PURCHASE_RECEIPT) return true;
            });
            return false;
        },
        showLoadAllCheckbox() {
            return executeFromVersion(24, 11) && this.action === 'load' && this.statusCode !== RIDE_STATUS.CONCEPT && this.packingSlip && ! this.allLoaded;
        },
        statusCode() {
            return this.ride.status.code;
        },
        supplierName() {
            if (this.purchaseReceipt) {
                return this.address.attentionOf
                    ? this.address.attentionOf
                    : this.purchaseReceipt.supplier.fullCompanyName
                ;
            }
            return null;
        },
        weight() {
            return roundTo(this.item.weight / 1000, 2, false);
        },
    },
    methods: {
        optionsView()
        {
            if (this.item.type && this.item.type === ITEM_TYPE.PURCHASE_RECEIPT) this.$router.push({
                name:   'item_options_view',
                params: {
                    item:   this.item,
                    itemId: this.item.id,
                    ride:   this.ride
                }
            });
            else if (
                (this.allLoaded && this.statusCode > RIDE_STATUS.CONCEPT)
                && this.settings.less_restrictions_for_starting_delivery
                || (this.statusCode >= RIDE_STATUS.LOADED && this.statusCode < RIDE_STATUS.COMPLETED)
                || (this.rideContainsPurchaseReceipt && this.statusCode < RIDE_STATUS.COMPLETED)
            ) this.$router.push({
                name: 'options_view',
                params: {
                    item:   this.item,
                    itemId: this.item.id,
                    ride:   this.ride
                }
            });
        },
    },
};
</script>
