
export const
    /** Item types (transport) */
    ITEM_TYPE = {
        ORDER:            'order',
        PACKING_SLIP:     'packingSlip',
        PURCHASE_ORDER:   'purchaseOrder',
        PURCHASE_RECEIPT: 'purchaseReceipt'
    },

    ITEM_STATUS = {
        NOT_STARTED: 0,
        UNDERWAY:    1,
        LOADED:      2,
        UNLOADED:    3,
        COMPLETED:   4
    },

    /** NOTIFICATION TYPES */
    NOTIFY_METHOD_EMAIL = 'email',
    NOTIFY_METHOD_PHONE = 'phone',
    NOTIFY_METHOD_SMS   = 'SMS',

    /** CONTEXTUAL COMMENT CONTEXTS FOR PACKING SLIPS */
    CONTEXT = {
        DELIVERY:                 'delivery',
        PACKING:                  'packing',
        PICK_UP:                  'pick_up',
        PURCHASE_GENERAL_RECEIPT: 'purchase_general_receipt',
        PURCHASE_RECEIPT:         'purchase_receipt'
    },

    /** PACKING SLIP STATUSES */
    PACKING_SLIP_STATUS_ACTIVE    = 0,
    PACKING_SLIP_STATUS_FINALIZED = 1,

    /** PICKING STATUSES */
    NOT_PICKED        = 0,
    PARTIALLY_PICKED  = 2,
    COMPLETELY_PICKED = 1,

    /** RELATION TYPES */
    RELATION_TYPE_CARRIER = 5, // carrier === driver

    /** TRANSPORT_RIDE INCLUDES */
    SINGLE_RIDE_INCLUDES = [
        'articleCustomFieldNames',
        'items',
        'items.order',
        'items.packingSlip.attachments',
        'items.packingSlip.contextualComments',
        'items.packingSlip.deliveryAddress',
        'items.packingSlip.deliveryLocationAttachments',
        'items.packingSlip.invoices.isMailableFromDriverApp',
        'items.packingSlip.invoices.paymentCondition',
        'items.packingSlip.isSigned',
        'items.packingSlip.lines.quantity.packaging',
        'items.packingSlip.pickingLocation',
        'items.packingSlip.picked',
        'items.packingSlip.relationNotifications',
        'items.pickUpAddress',
        'items.purchaseReceipt.attachments',
        'items.purchaseReceipt.contextualComments',
        'items.purchaseReceipt.isSigned',
        'items.purchaseReceipt.relationNotifications',
        'status',
        'vehicle'
    ].join(','),

    INVOICE_PAYMENT_INCLUDES = [
        'invoice.isMailableFromDriverApp',
        'invoice.paymentCondition',
    ].join(','),

    /** TRANSPORT RIDE STATUSES */
    RIDE_STATUS = {
        CONCEPT:        0,
        PLANNED:        1,
        BEING_PREPARED: 2,
        LOADED:         3,
        IN_TRANSIT:     4,
        COMPLETED:      5
    },

    PURCHASE_RECEIPT_LINE_LOAD_STATUS = {
        NOT_LOADED: 0,
        LOADED:     1,
        UNLOADED:   2
    }
;
