
export function setSettings(value) {
    sessionStorage.setItem('settings', JSON.stringify(value));
}

export function getSettings() {
    const defaultSettings = {
        always_display_customer_at_options_view: false,
        dateNumber:                              0, // Not an actual "Retail" setting...
        less_restrictions_for_starting_delivery: false
    }
    let settings = sessionStorage.getItem('settings');
    if (settings) {
        settings = JSON.parse(settings);

        for (const [setting, value] of Object.entries(defaultSettings)) {
            // Make sure we have all settings available
            if (settings[setting] === undefined) {
                settings[setting] = value;
            }
        }
        return settings;

    } else {
        // Return the default setting values so we always have the settings no mather in which version the administration is
        return defaultSettings;
    }
}
