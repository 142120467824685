/**
 * Function to round in the safest way possible in javascript (since Math.round() and toFixed() both are limited) i.e.:
 * - Math.round(1.005 * 1000) / 1000; returns 1 instead of expected 1.01.
 * - parseFloat("1.5550").toFixed(2); returns 1.55 instead of 1.56.
 *
 * @param { Number | String }        numberToRound
 * @param { Number | String | null } decimalPlaces
 * @param { Boolean }                returnAsNumber
 *
 * @return { String | Number }
 */
function roundTo(numberToRound, decimalPlaces, returnAsNumber = false)
{
    // There is no option that will omit the sign for both positive numbers *and* negative zero, so we use the exceptZero option and strip the positive sign
    let returnValue = Number(numberToRound).toLocaleString('en-US', {
        useGrouping:           false,
        maximumFractionDigits: decimalPlaces === null ? undefined : decimalPlaces,
        signDisplay:           'exceptZero',
    });

    returnValue = (returnValue[0] === '+')
        ? returnValue.substr(1)
        : returnValue
    ;

    return returnAsNumber
        ? Number(returnValue)
        : returnValue
    ;
}

export default roundTo;
