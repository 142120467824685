let codeVersion = null;

export function setCodeVersion(value)
{
    codeVersion = value;
}

/**
 * @param { Number } major
 * @param { Number } minor
 * @return { Boolean }
 */
export function executeFromVersion(major, minor)
{
    if (! codeVersion) {
        return false;
    } else if (codeVersion.major === 'source' || codeVersion.major > major) {
        return true;
    } else if (codeVersion.major < major) {
        return false;
    }
    return codeVersion.minor >= minor;
}
